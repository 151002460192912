import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día 30",
  week: "Semana 5",
  month: "abr",
  day: "12",
  monthNumber: "04",
  date: "2020-04-12",
  path: "/cronologia/semana-05#dia-12-abr",
};

const Day30 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModImage src="/images/svg/1204-subway.svg" alt="estacion metro" />
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-12"} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 4.167 nuevos positivos y 510 personas fallecidas. Reciben
          el alta 3.441 personas. El número de casos ingresados en la UCI
          asciende a 7.535, lo que supone un aumento de 53 personas.
        </ModText>
        <ModText>
          Cambios en la forma de computar los casos hospitalizados impiden
          calcular el número de casos antendidos por los servicios de salud.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,1 % y la tasa de recuperados del 36,5 %.
        </ModText>
        <ModAnimation svg="/images/anim/12_abril_aeropuerto_militar_00.svg" />
        <ModText>
          Hasta la fecha de hoy, un total de <strong>89.780 militares</strong>{" "}
          han participado en la <strong>Operación Balmis</strong> desarrollada
          por las Fuerzas Armadas como respuesta a la epidemia de coronavirus
          que afecta a España. Desde el pasado 15 de marzo, se han realizado
          10.960 intervenciones en 1.207 poblaciones de todas las comunidades
          autónomas.
        </ModText>
        <ModText>
          Los <strong>servicios de transporte</strong> recuperan a partir de
          mañana, 13 de abril, los niveles de servicio de la segunda semana de
          confinamiento. Se realizará un ajuste de la oferta de los servicios de
          Cercanías, reforzando servicios en hora punta, fundamentalmente en
          Madrid y Barcelona, de modo que se garantice el cumplimiento de las
          ratios recomendados de distanciamiento entre los viajeros.
        </ModText>
        <ModText>
          En cuanto a la oferta en los servicios de Media Distancia
          Convencional, será esencialmente la misma que se está prestando
          actualmente, suficiente para garantizar las distancias mínimas y para
          dar respuesta a la mínima demanda de transporte existente actualmente.
        </ModText>
        <ModTwoCols
          src="/images/svg/12_abr_bus.svg"
          alt="vehículos militares sanitarios"
          small={false}
        >
          El Gobierno ha repartido en puntos logísticos de toda España un total
          de <strong>10 millones de mascarillas</strong>, que empezarán a
          distribuirse a partir de mañana 13 de abril, en los{" "}
          <strong>principales nodos de transporte público</strong> del país,
          para aquellas personas que tengan que desplazarse a sus puestos de
          trabajo y sea más complejo mantener la distancia de seguridad.
        </ModTwoCols>
        <ModReferenceList title="GUÍAS Y DOCUMENTOS PUBLICADOS">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/presidencia/Documents/2020/13042020_CAMPANAREPARTO.pdf"
            name="Campaña especial de reparto de mascarillas entre CCAA"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day30;
